<template>
	<nav :class="{ closed: closeMenu }" class="nav-mobile">
		<a v-show="!scrolled" class="logo logo-w" href="/">
			<img src="@/assets/images/NWLOGO.svg" alt="" />
		</a>
		<a v-show="scrolled" class="logo logo-b" href="/">
			<img src="@/assets/images/NWLOGO-black.svg" alt="" />
		</a>
		<hamburger :scrolled="scrolled" />
		<mobile-menu />
	</nav>
</template>

<script>
export default {
	name: 'Nav',
	components: {
		hamburger: () => import('@/components/Hamburger.vue'),
		'mobile-menu': () => import('@/components/MobileMenu.vue'),
	},
	props: {
		scrolled: {
			type: Boolean,
		},
		closeMenu: {
			type: Boolean,
		},
	},
}
</script>
<style scoped lang="scss">
.nav-mobile {
	height: 87px;
	width: 100%;
	z-index: 9999;
	display: none;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 1024px) {
		display: flex;
	}
	&.menu-open {
		background-color: #343735;
		.logo-b {
			display: none;
		}
		.logo-w {
			display: block !important;
		}
	}
	.logo {
		width: 200px;
	}
}
</style>
